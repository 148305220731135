<template>
  <main>
    <div class="py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="md:flex md:items-center md:justify-between mb-5">
        <div class="flex-1 min-w-0">
          <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            Projects
          </h2>
          <span v-if="currentUser" class="inline-block text-sm text-gray-600">{{projects.length}} of {{limit}}</span>
        </div>
        <div v-if="currentUser" class="mt-4 flex md:mt-0 md:ml-4">

          <button :disabled="limitReached" @click="addProject" type="button" class="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Create a new project
          </button>
        </div>
      </div>

      <div v-if="currentUser">
        <div v-if="!projects.length">
          <span class="text-gray-600">There are no Projects, create one.</span>
        </div>

        <div v-if="isLoading" class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8 text-center pt-2">
          <div class="inline-flex text-base leading-6 font-medium rounded-md text-white text-gray-600 cursor-not-allowed">
            <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
              <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Loading...
          </div>
        </div>

        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="bg-white shadow overflow-hidden sm:rounded-md">
          <ul class="divide-y divide-gray-200">
            <li v-for="project in projects" v-bind:key="project.id">
              <a @click="projectEditStart(project.id)" class="block cursor-pointer hover:bg-gray-50">
                <div class="flex items-center px-4 py-4 sm:px-6">
                  <div class="min-w-0 flex-1 flex items-center">
                    <div class="flex-shrink-0">
                      <div class="h-12 w-12 rounded-full"
                           :class="'bg-' + project.color + '-400'"
                      ></div>
                    </div>
                    <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                      <div>
                        <p class="text-sm font-medium truncate"
                           :class="'text-' + project.color + '-500'"
                        >{{project.name}}</p>
                        <p class="mt-2 flex items-center text-sm text-gray-500">
                          <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <span class="truncate">{{createdDate(project.created_at)}}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div v-else class="py-6 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="rounded-md shadow-md bg-yellow-50 p-4 mb-5">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/exclamation -->
              <svg class="border-2 border-yellow-200 h-12 w-12 text-yellow-400 bg-yellow-100 p-2 rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3 flex-grow">
              <h3 class="text-md font-medium text-yellow-800">
                You are using free version of Kairo where Projects are not available.
              </h3>
              <div class="mt-1 text-sm text-yellow-700">
                <p>
                  Sign up and subscribe to keep your data and access reports from anywhere.
                </p>
              </div>
            </div>
            <div class="flex-shrink">
              <router-link
                      to="signup"
                      class="mt-2 inline-flex items-center px-3 py-2 border-2 border-yellow-200 text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >Start trial
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>

  <div v-if="projectEdited.id" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <transition
    enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-from-class="translate-x-0"
    leave-to-class="translate-x-full"
  >
    <ProjectEdit
      v-if="projectEdited.id"
      v-bind:key="projectEdited.id"
      v-bind:id="projectEdited.id"
      v-bind:project="projectEdited"
      @project-edit-stop="projectEditStop"
      @project-update="projectUpdate"
      @project-delete="projectDelete"
    ></ProjectEdit>
  </transition>
</template>

<script>
  import {mapActions, mapGetters} from "vuex";
  import {colors} from '../colors/colors.js';
  import ProjectEdit from "../components/ProjectEdit";
  import {getProjects, createProject, updateProject, deleteProject} from "../models/ProjectsModel";
  import {v4 as uuidv4} from "uuid";
  import { useToast } from "vue-toastification";
  import * as dayjs from "dayjs";

  export default {
    components: {
      ProjectEdit
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        projects: this.$store.state.projects.projects,
        projectEdited: {},
        isLoading: false,
        limit: 6,
        colors
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      limitReached() {
        return this.projects >= this.limit;
      }
    },
    methods: {
      ...mapActions(["projectsResetAll"]),
      addProject() {
        const color = this.getRandomColor();
        const randomId = uuidv4();
        const project = {
          id: randomId,
          name: 'Project name',
          color: color,
          created_at: new Date().getTime(),
          refID: null
        };

        this.$store.commit('projectAdd', project);

        if (this.currentUser) {
          createProject(project)
              .then((res) => {
                this.$store.commit('projectUpdateRef', {id: project.id, refID: res.ref.value.id});
                this.projectEditStart(project.id);
              })
              .catch(() => {
                this.toast.error(`We are not able to create the project.`)
              });
        }
      },
      getRandomColor() {
        return this.colors[Math.floor(Math.random() * this.colors.length)].value;
      },
      createdDate(created_at) {
        return dayjs(created_at).format('HH:mm DD MMM YYYY')
      },
      projectEditStart(projectId) {
        let index = this.projects.findIndex(item => item.id === projectId);
        this.projectEdited = this.projects[index];
      },
      projectEditStop() {
        this.projectEdited = {};
      },
      projectUpdate(projectRefID, projectId, projectData) {
        if (projectData.name) this.projectEditName(projectId, projectData.name);
        if (projectData.color) this.projectEditColor(projectId, projectData.color);

        if (this.currentUser) {
          updateProject(projectRefID, projectData);
        }
      },
      projectEditName(projectId, name) {
        this.$store.commit('projectEditName', {
          id: projectId,
          name: name
        });
      },
      projectEditColor(projectId, color) {
        this.$store.commit('projectEditColor', {
          id: projectId,
          color: color
        });
      },
      projectDelete(projectRefId, projectId) {
        this.$store.commit('projectDelete', projectId);
        this.projectEdited = {};

        if (this.currentUser) {
          deleteProject(projectRefId)
              .then(() => {
              })
              .catch(() =>  {
                this.toast.error(`We were not able to delete the project.`)
              });
        }
      },
    },
    mounted() {
      if (this.currentUser) {
        this.projectsResetAll();
        this.isLoading = true;

        getProjects()
            .then(resp => {
              let projectsData = resp.data;
              let projects = [];

              projectsData.forEach(function (project) {
                project.data.refID = project.ref.value.id;
                projects.push(project.data);
              });
              this.$store.commit('projectsAdd', projects);
              this.isLoading = false;
            })
            .catch(() => {
                this.toast.error(`We are not able to fetch projects from the database.`);
                this.isLoading = false;
              }
            );
      }
    }
  }

</script>
